import Vue from "vue";
import store from "@/store/index"; //直接导入store对象
import { Message } from "element-ui";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/lottery",
    name: "Lottery",
    component: () => import("../views/MyCenter/lottery.vue"),
    meta: {
      title: "抽奖页面",
    },
  },
  {
    path: "/intro",
    name: "Intro",
    component: () => import("../components/intro/intro.vue"),
    meta: {
      title: "新手引导",
    },
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import("../views/homeFooterPage/AboutUs.vue"),
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/registration-agreement",
    name: "RegistrationAgreement",
    component: () =>
      import("../views/homeFooterPage/RegistrationAgreement.vue"),
    meta: {
      title: "注册协议",
    },
  },
  {
    path: "/copyright-notice",
    name: "CopyrightNotice",
    component: () => import("../views/homeFooterPage/CopyrightNotice.vue"),
    meta: {
      title: "版权声明",
    },
  },
  {
    path: "/business-introduction",
    name: "BusinessIntroduction",
    component: () => import("../views/homeFooterPage/BusinessIntroduction.vue"),
    meta: {
      title: "业务介绍",
    },
  },
  {
    path: "/using-tutorials",
    name: "UsingTutorials",
    component: () => import("../views/homeFooterPage/Using-Tutorials.vue"),
    meta: {
      title: "使用教程",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/registerPage/Register.vue"),
    meta: {
      title: "注册页面",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/registerPage/Login.vue"),
    meta: {
      title: "登录页面",
    },
  },
  {
    path: "/home/weixin",
    name: "weixin",
    component: () => import("../components/home/weiXin.vue"),
    meta: {
      title: "微信用户绑定页面",
    },
  },
  {
    path: "/KnowledgeContest",
    name: "KnowledgeContest",
    component: () =>
      import("../components/KnowledgeContest/KnowledgeContest.vue"),
    meta: {
      title: "知识竞赛",
    },
  },
  {
    path: "/pagetwo",
    name: "pagetwo",
    component: () => import("../views/pageTwo.vue"),
    meta: {
      title: "活动底页",
    },
    children: [
      {
        path: "/ReadingMonth",
        name: "ReadingMonth",
        component: () => import("@/views/ReadingMonth/ReadingMonth.vue"),
        meta: {
          title: "读书月活动页",
        },
      },
      {
        path: "/DemoPage",
        name: "DemoPage",
        component: () => import("@/views/ReadingMonth/DemoPage.vue"),
        meta: {
          title: "读书月活动演示页",
        },
      },
      {
        path: "/home-page/activityRegistration",
        name: "activityRegistration",
        component: () => import("@/views/activityRegistration/index.vue"),
        meta: {
          title: "知识图谱大赛报名",
        },
      },
      {
        path: "/home-page/contribute",
        name: "contribute",
        component: () => import("@/views/activePlate/contribute.vue"),
        meta: {
          title: "活动列表",
        },
      },
      {
        path: "/home-page/DetailsWorks",
        name: "DetailsWorks",
        component: () => import("@/views/activePlate/DetailsWorks.vue"),
        meta: {
          title: "作品详情",
        },
      },
      {
        path: "/home-page/detailEvent",
        name: "detailEvent",
        component: () => import("@/views/activePlate/detailEvent"),
        meta: {
          title: "投稿活动详情",
        },
        children: [
          {
            path: "/home-page/detailEvent/synopsis",
            name: "synopsis",
            component: () =>
              import("@/views/activePlate/contributionContent/synopsis.vue"),
            meta: {
              title: "活动简介",
            },
          },
          {
            path: "/home-page/detailEvent/learningZone",
            name: "learningZone",
            component: () =>
              import(
                "@/views/activePlate/contributionContent/learningZone.vue"
              ),
            meta: {
              title: "学习专区",
            },
          },
          {
            path: "/home-page/detailEvent/worksShow",
            name: "worksShow",
            component: () =>
              import("@/views/activePlate/contributionContent/worksShow.vue"),
            meta: {
              title: "作品展示",
            },
          },
          {
            path: "/home-page/detailEvent/study",
            name: "study",
            component: () =>
              import("@/views/activePlate/contributionContent/study.vue"),
            meta: {
              title: "我要投稿",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/home-page",
    name: "home-page",
    component: () => import("../views/HomePage.vue"),
    children: [
      {
        path: "/home-page/my-center",
        name: "MyCenter",
        component: () => import("../views/MyCenter/mycenter.vue"),
        children: [
          {
            path: "/home-page/my-center/personalinformation0",
            name: "personalinformation",
            title: "个人信息",
            component: () =>
              import("../components/MyCenter/personalinformation.vue"),
          },
          {
            path: "/home-page/my-center/personalinformation1",
            name: "mydatabase",
            title: "我的数据库",
            component: () => import("../components/MyCenter/mydatabase.vue"),
          },
          {
            path: "/home-page/my-center/personalinformation2",
            name: "mymembers",
            title: "我的会员",
            component: () => import("../components/MyCenter/mymembers.vue"),
          },
          {
            path: "/home-page/my-center/personalinformation3",
            name: "mypoints",
            title: "我的积分",
            component: () => import("../components/MyCenter/mypointsTwo.vue"),
          },
          {
            path: "/home-page/my-center/personalinformation4",
            name: "medicinecircle",
            title: "我的中医圈",
            component: () =>
              import("../components/MyCenter/medicinecircle.vue"),
          },
          {
            path: "/home-page/my-center/personalinformation5",
            name: "messagenotification",
            title: "消息通知",
            component: () =>
              import("../components/MyCenter/messagenotification.vue"),
          },
          {
            path: "/home-page/my-center/personalinformation6",
            name: "mytracks",
            title: "我的足迹",
            component: () => import("../components/MyCenter/mytracks.vue"),
          },
          {
            path: "/home-page/my-center/personalinformation7",
            name: "commenton",
            title: "我的评论",
            component: () => import("../components/MyCenter/commenton.vue"),
          },
          {
            path: "/home-page/my-center/personalinformation8",
            name: "annotateandcomment",
            title: "我的批注",
            component: () =>
              import("../components/MyCenter/annotateandcomment.vue"),
          },
          {
            path: "/home-page/my-center/personalinformation9",
            name: "collect",
            title: "我的收藏",
            component: () => import("../components/MyCenter/collect.vue"),
          },
          {
            path: "/home-page/my-center/personalinformation10",
            name: "prescriptionstore",
            title: "我的方剂库",
            component: () =>
              import("../components/MyCenter/prescriptionstore.vue"),
          },
          {
            path: "/home-page/my-center/personalinformation11",
            name: "leading-out",
            title: "我的导出",
            component: () => import("../components/MyCenter/leading-out.vue"),
          },
          {
            path: "/home-page/my-center/personalinformation12",
            name: "purchasedresources",
            title: "已购资源",
            component: () =>
              import("../components/MyCenter/purchasedresources.vue"),
          },
          {
            path: "/home-page/my-center/personalinformation13",
            name: "changepassword",
            title: "修改密码",
            component: () =>
              import("../components/MyCenter/changepassword.vue"),
          },
        ],
      },
      {
        path: "/DetailedIntroduction",
        name: "DetailedIntroduction",
        component: () => import("../views/MyCenter/DetailedIntroduction.vue"),
        meta: {
          title: "充值页面",
        },
      },
      {
        path: "/home-page/contact-us",
        name: "ContactUs",
        component: () => import("@/views/homeFooterPage/ContactUs.vue"),
        meta: {
          title: "联系我们",
        },
      },
      {
        path: "/home-page/system-notifications",
        name: "system-notifications",
        component: () =>
          import("../components/HomePage/SystemNotificationsList.vue"),
        meta: {
          title: "系统通知——列表页",
        },
      },
      {
        path: "/home-page/notifications",
        name: "notifications",
        component: () =>
          import("../components/HomePage/SystemNotifications.vue"),
        meta: {
          title: "系统通知——详情页",
        },
      },
      {
        path: "/home-page/article-details",
        name: "articleDetails",
        component: () => import("../components/HomePage/ArticleDetails.vue"),
        meta: {
          title: "文章详情",
        },
      },
      {
        path: "/home-page/advancedretrieval",
        name: "AdvancedRetrieval",
        component: () => import("../components/HomePage/AdvancedRetrieval.vue"),
        meta: {
          title: "高级搜索",
        },
      },
      {
        path: "/searchresult",
        name: "searchresult",
        component: () => import("../views/searchResult/searchResult.vue"),
        meta: {
          title: "搜索结果",
        },
      },
      {
        path: "/visualization",
        name: "visualization",
        component: () => import("../components/searchResult/Visualization.vue"),
        meta: {
          title: "搜索结果-可视化",
        },
      },
      {
        path: "/home-page/notice",
        name: "notice",
        component: () => import("../components/notice/notice.vue"),
        meta: {
          title: "博览资讯-查看更多",
        },
      },
      {
        path: "/home-page/medicinecircle",
        name: "medicinecircleB",
        component: () =>
          import("../views/ChineseMedicineCircle/MedicineCircle.vue"),
        meta: {
          title: "中医圈二级",
        },
      },
      {
        path: "/home-page/detailed-information",
        name: "detailedinformation",
        component: () =>
          import("../views/ChineseMedicineCircle/DetailedInformation.vue"),
        meta: {
          title: "中医圈详情",
        },
      },
      {
        path: "/home-page/itemcontent",
        name: "ItemDetails",
        component: () => import("../components/HomePage/item-content.vue"),
        meta: {
          title: "条目详情",
        },
      },
      {
        path: "/home-page/Details",
        name: "Details",
        component: () => import("../components/BookDetails/Details.vue"),
        meta: {
          title: "图书详情",
        },
      },
      {
        path: "/home-page/BookPageOne",
        name: "BookPageOne",
        component: () => import("../components/BookDetails/BookPageOne.vue"),
        meta: {
          title: "全文阅读",
        },
      },
      {
        path: "/home-page/BooksPdf",
        name: "BooksPdf",
        component: () => import("../components/BookDetails/BooksPdf.vue"),
        meta: {
          title: "pdf阅读",
        },
      },
      {
        path: "/home-page/wisdom",
        name: "wisdom",
        component: () => import("@/views/wisdom/index.vue"),
        meta: {
          title: "智慧开方",
        },
      },
      {
        path: "/InstitutionalTrials",
        name: "InstitutionalTrials",
        component: () =>
          import("@/views/InstitutionalTrials/InstitutionalTrials.vue"),
        meta: {
          title: "机构试用申请页",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior() {
  //   return { x: 0, y: 0 }
  // }
});

const baiRouterName = [
  "home",
  "Lottery",
  "weixin",
  "medicinecircleB",
  "notice",
  "Login",
  "register",
  "UsingTutorials",
  "BusinessIntroduction",
  "CopyrightNotice",
  "RegistrationAgreement",
  "AboutUs",
];
router.beforeEach((to, from, next) => {
  const isToken = VueCookies.get("token");
  if (isToken) {
    next();
  } else {
    if (!baiRouterName.some((i) => i == to.name)) {
      window.localStorage.setItem("backUrl", window.location.href);
      console.log(window.localStorage.getItem("backUrl"), "返回地址");
      next({ path: "/" });
      Message.warning("请先登录");
    } else {
      next();
    }
  }
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject)
    return originalPush.call(this, location, resolve, reject);
  return originalPush.call(this, location).catch((e) => {});
};
export default router;
